<template>
  <div>
    <div class="navigation-bar">
      <span @click="back" class="back-button">
        <font-awesome-icon icon="fa-solid fa-arrow-left"></font-awesome-icon>
      </span>

      <div>
        Balance Due:<h3> {{ $utils.formatMoney(saleTotal - discount.total) }}</h3>
      </div>

      <a @click="open" class="barcode-scanner viewOrder text-white">
        <span class="badge" v-if="saleUnits>0">{{ saleUnits }}</span>
        <font-awesome-icon icon="fa-solid fa-cart-shopping"/>
      </a>
      <!-- <div class="d-flex flex-row">
        <div class="d-flex flex-column">
          <span>Balance Due:</span>
          <h3> {{ $utils.formatMoney(saleTotal - discount.total) }}</h3>
        </div>
      </div> -->
    </div>

    <!-- <div class="float-end">
      <a class="btn btn-primary btn-sm text-sm mx-2" @click="openLPO" v-if="!storeLPONumber">Add LPO</a>
      <a class="btn btn-primary btn-sm text-sm mx-2" @click="removeLPO" v-else>Remove LPO</a>
    </div>
    <custom-modal :is-form="true" ref="lpoModal" @submit="setLPO" title="Select LPO Customer">
      <div class="d-flex flex-column">
        <label for="lpo_debtor">Customer</label>
        <v-select id="lpo_debtor"
                  v-model="lpoDetails.debtorID"
                  :options="$store.getters.getDebtors"
                  :reduce="debtor=>debtor.Id"
                  label="Name"
                  :required="!lpoDetails.debtorID"
                  @search="searchDebtors"
        ></v-select>

        <label class="mt-2">
          LPO NO.
          <input class="form-control" required type="text" minlength="10" maxlength="10" v-model="lpoDetails.lpoNumber">
        </label>
      </div>
    </custom-modal> -->

    <div class="payment-body">
      <div class="container mb-2">
        <div class="row d-flex justify-content-center">

          <label class="mb-3">
            <b class="text-center">Amount Paid</b>
            <input class="input-amount"
                   oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '0');"
                   pattern="[+-]?([0-9]*[.])?[0-9]+" type="text" v-model="paidAmount">
          </label>

          <div class="row" v-if="!mobile">
            <div class="col-lg p-5" >
              <div class="container button-pane">
                <div class="row text-center d-flex flex-column align-items-center">
                  <div class="col-12 d-inline-flex justify-content-center">
                    <button @click="inputNumber($event)" class="btn" type="button">1</button>
                    <button @click="inputNumber($event)" class="btn" type="button">2</button>
                    <button @click="inputNumber($event)" class="btn" type="button">3</button>
                  </div>
                  <div class="col-12 d-inline-flex justify-content-center">
                    <button @click="inputNumber($event)" class="btn" type="button">4</button>
                    <button @click="inputNumber($event)" class="btn" type="button">5</button>
                    <button @click="inputNumber($event)" class="btn" type="button">6</button>
                  </div>
                  <div class="col-12 d-inline-flex justify-content-center">
                    <button @click="inputNumber($event)" class="btn" type="button">7</button>
                    <button @click="inputNumber($event)" class="btn" type="button">8</button>
                    <button @click="inputNumber($event)" class="btn" type="button">9</button>
                  </div>
                  <div class="col-12 d-inline-flex justify-content-center">
                    <button @click="addPoint" class="btn" type="button">
                      .
                    </button>
                    <button @click="inputNumber($event)" class="btn" type="button">0</button>
                    <button @click="backspace" class="btn" type="button">
                      <font-awesome-icon icon="fa-solid fa-delete-left"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <DesktopPaymentMethod
                  @processPayment="processTransaction"
              />
            </div>
          </div>
          <div class="container button-pane" v-if="mobile" >
            <div class="row text-center d-flex flex-column align-items-center">
              <div class="col-12 d-inline-flex justify-content-center">
                <button @click="inputNumber($event)" class="btn" type="button">1</button>
                <button @click="inputNumber($event)" class="btn" type="button">2</button>
                <button @click="inputNumber($event)" class="btn" type="button">3</button>
              </div>
              <div class="col-12 d-inline-flex justify-content-center">
                <button @click="inputNumber($event)" class="btn" type="button">4</button>
                <button @click="inputNumber($event)" class="btn" type="button">5</button>
                <button @click="inputNumber($event)" class="btn" type="button">6</button>
              </div>
              <div class="col-12 d-inline-flex justify-content-center">
                <button @click="inputNumber($event)" class="btn" type="button">7</button>
                <button @click="inputNumber($event)" class="btn" type="button">8</button>
                <button @click="inputNumber($event)" class="btn" type="button">9</button>
              </div>
              <div class="col-12 d-inline-flex justify-content-center">
                <button @click="addPoint" class="btn" type="button">
                  .
                </button>
                <button @click="inputNumber($event)" class="btn" type="button">0</button>
                <button @click="backspace" class="btn" type="button">
                  <font-awesome-icon icon="fa-solid fa-delete-left"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <span class="badge bg-info m-2" v-if="tipAmount > 0">Tip : {{ $utils.formatMoney(tipAmount) }}</span>

      <div class="row m-1">
        <label for="saleReference">Reference / Note(s)</label>
        <textarea class="form-control" placeholder="Reference / Note(s)" rows="3"
                  required id="saleReference" v-model="reference"/>
      </div>

      <div class="container d-flex justify-content-center mb-3">
        <div class="discount-section row align-items-center">
          <h5>  Apply a Discount (Optional)</h5>
          <div class="discount-box d-flex justify-content-between gap-1">
            <p @click="openDiscount" v-if="!discountApplied">Tap to apply a discount</p>
            <p v-else>{{ discountText }}</p>
            <div class="d-flex justify-content-end gap-3">
              <a v-if="discountApplied" class="icon text-danger" @click="clearDiscount">
                <font-awesome-icon icon="fa-solid fa-xmark"/>
              </a>
              <a class="icon" @click="openDiscount">
                <font-awesome-icon icon="fa-solid fa-chevron-right"/>
              </a>
            </div>
          </div>
        </div>
      </div>

      <DiscountSheet ref="discount"/>

      <div class="col-12 d-flex flex-column align-items-center justify-content-center bg-white fixed-bottom" v-if="mobile">
        <button :style="disabled" @click="process"
                class="payment-btn d-block">
          <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          {{ textMsg }}
        </button>
      </div>
      <!--      <div class="d-flex justify-content-center" v-if="mobile">-->
      <!--        <button :style="disabled" @click="process" class="payment-btn">-->
      <!--          <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>-->
      <!--          {{ textMsg }}-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
    <MethodsBottomSheet ref="paymentMethods"/>
  </div>
</template>

<script>
import DesktopPaymentMethod from "@/components/point-of-sale/sales/payment/DesktopPaymentMethod";
import DiscountSheet from "@/components/point-of-sale/sales/Discount/DiscountSheet";
import CurrentSaleTable from "@/components/point-of-sale/sales/CurrentSaleTable";
import ViewItems from "@/components/point-of-sale/sales/payment/ViewItems";
import SaleDetails from "@/components/point-of-sale/sales/SaleDetails";
import MethodsBottomSheet from "@/components/point-of-sale/sales/payment/MethodsBottomSheet";
import CustomModal from "@/components/layout/utils/CustomModal";

export default {
  name: "PaymentScreen",
  components: {
    DesktopPaymentMethod,
    CustomModal,
    MethodsBottomSheet,
    SaleDetails,
    ViewItems,
    CurrentSaleTable,
    DiscountSheet,
  },
  computed: {
    saleUnits() {
      return this.$store.getters.getSaleUnits;
    },
    tipAmount() {
      return this.$store.getters.getTipAmount;
    },
    discountApplied() {
      return this.discount.type !== 'none';
    },
    disabled() {
      return this.invalid || this.loading
          ? { background: "#9e9e9e", "pointer-events": "none" }
          : { background: "#9ccc65" };
    },
    invalid() {
      if (this.paidAmount === "") return false;
      let paid = isNaN(parseFloat(this.paidAmount)) ? 0 : parseFloat(this.paidAmount);
      let balance = parseFloat(this.saleTotal) - parseFloat(this.discount.total);
      return paid < balance;
    },
    saleTotal() {
      return this.$store.getters.getSaleTotal + this.$store.getters.getTipAmount;
    },
    discountText() {
      let discount = this.discount;
      return `${discount.type} (${discount.type === "Percentage" ? `${discount.value}%` : discount.value})`;
    },
    discount() {
      return this.$store.getters.getSaleDiscount;
    },
    change() {
      let val = this.paidAmount - Number((this.saleTotal - this.discount.total))
      return val < 0 ? 0 : val.toFixed(2);
    },
    textMsg() {
      return this.loading ? "Processing Transaction" : "Process Transaction";
    },
    saleDetails() {
      return this.$store.getters.getSaleDetails
    },
    currentSaleLines() {
      return this.$store.getters.getSaleLines
    },
    storeLPONumber() {
      return this.$store.getters.getLPO;
    },
    updateCardTotal() {
      return this.$store.dispatch(`validateSecurityPermissionNoPrompt`,`updateCardTotal`);
    },
  },
  data() {
    return {
      mobile: false,
      paidAmount: "",
      paymentType: "Cash",
      loading: false,
      reference: "",
      lpoDetails: {
        debtorID: null,
        lpoNumber: null,
      },
    };
  },
  beforeMount() {
    console.log("thank you");
    this.process();
  },
  methods: {
    open() {
      return this.$router.push({name: 'viewSale'})
    },
    checkScreenSize() {
      this.mobile = window.innerWidth <= 768;
    },
    clearDiscount() {
      return this.$store.commit("clearDiscount");
    },

    async openDiscount() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, `Discount`)
      if (!isAllowed) return;
      this.$refs.discount.open();
    },
    checkPaidAmount() {
      if (this.paidAmount === "") {
        let amount = parseFloat(this.saleTotal) - parseFloat(this.discount.total);
        return amount < 0 ? 0 : amount;
      }
      return this.paidAmount;
    },
    updatePaidAmount(value) {
      this.paidAmount = parseFloat(value).toFixed(2);
    },
    backspace() {
      this.paidAmount = this.paidAmount.slice(0, -1);
    },
    addPoint() {
      if (!this.paidAmount.includes(".")) this.paidAmount += ".";
    },
    process() {
      if (this.currentSaleLines.length === 0) {
        return responses.showWarning('You need to add Items to Sale!');
      }

      this.$store.commit('setSaleDetails', {
        refund: false,
        reference: this.reference,
        total: parseFloat(this.saleTotal.toFixed(2)),
        paid: this.checkPaidAmount(),
        change: parseFloat(Number(this.change).toFixed(2))
      });
      this.$refs.paymentMethods.open();
    },

    // searchDebtors(searchTerm) {
    //   if (searchTerm) this.$store.dispatch("fetchDebtors", searchTerm);
    // },
    // openLPO() {
    //   this.$refs.lpoModal.showModal();
    // },
    // removeLPO() {
    //   this.$store.commit("setLPO", null);
    // },
    // setLPO() {
    //   this.$store.commit("setLPO", this.lpoDetails.lpoNumber);
    //   this.lpoDetails = { debtorID: null, lpoNumber: null };
    // },
    async openCashDrawer() {
      return this.$store.dispatch(`openCashDrawer`)
    },
    back() {
      this.$store.dispatch(`calculateTipAmount`);
      return this.$router.push({
        name: `pos`,
        query: {
          saleNumber: this.$store.getters.getCurrentSale,
          dontReload: true
        }
      });
    },
    processTransaction(type){
      this.$utils.showLoading();
      console.log(`Payment Type : `+type)
      // this.setSwipe();
      let obj = this.saleDetails;
      obj.type = type;
      this.openCashDrawer(); // Call openCashDrawer method here
      return this.$store.dispatch("processTransaction", {
        ...obj,
        refund: false,
        reference: this.reference,
        total: parseFloat(this.saleTotal.toFixed(2)),
        paid: this.checkPaidAmount(),
        change: parseFloat(Number(this.change).toFixed(2))
      })
          .then((res) => {
            if (res) {
              if (!this.$store.getters.getAndroid) {
              }
              return this.$router.push({name: 'viewPayment'})
            }
          }).finally(() => {
            // this.setSwipe();
            this.$utils.hideLoading();
          });
    },
    inputNumber(event) {
      let elem = event.target
      if (elem) {
        let txt = elem.textContent || elem.innerText;
        if (txt) {
          if (this.paidAmount === '0') {
            this.paidAmount = ''
          }
          this.paidAmount += txt
        }
      }
    },
    setLPO() {
      if (!this.lpoDetails.debtorID) {
        responses.showWarning(`Please Select a Customer`)
        return this.$refs.lpoModal.openModal()
      }
      this.$store.commit(`setLPO`, this.lpoDetails);
      this.$store.commit(`setCustomer`, this.lpoDetails.debtorID);
      return responses.showSuccess(`LPO set successfully`);
    },
    removeLPO() {
      return Swal.fire({
        icon: `question`,
        title: `Do you want also want to remove customer from sale `,
        showCancelButton: true,
        confirmButtonText: `Sure`,
        cancelButtonText: `No , only remove LPO from sale`,
      }).then(res => {
        if (res.isConfirmed) {
          this.$store.commit(`setCustomer`, ``)
          this.lpoDetails.debtorID = ``
        }
        this.$store.commit(`setLPO`, {
          debtorID: this.lpoDetails.debtorID,
          lpoNumber: undefined,
        });
        this.lpoDetails.lpoNumber = ``
        return responses.showInfo(`LPO removed successfully`)
      })
    },
    openLPO() {
      this.$refs.lpoModal.openModal();
      let obj = this.lpoDetails;
      this.lpoDetails = {
        debtorID: obj.debtorID ? obj.debtorID : this.$store.getters.getCustomer,
        lpoNumber: obj.lpoNumber ? obj.lpoNumber : this.$store.getters.getLPO,
      }
    },
    searchDebtors(input, loading) {
      loading(true);
      return this.$store.dispatch(`getAllDebtors`, {
        Name: input,
        tpin: "",
        contact: ""
      }).finally(() => loading(false))
    },
    setAmount() {
      if (!this.paidAmount) {
        this.paidAmount = Number(this.saleTotal - this.discount.total).toFixed(2)
      }
    }
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
};
</script>

<style lang="scss" scoped>
button {
  background: #9ccc65;
  border-radius: 10px !important;
  border: 1px solid #9e9e9e !important;
  height: 60px;
  width: 100px;
  outline: none;
  margin: 1px;
  box-shadow: -2px -2px 5px white, 3px 3px 5px rgb(0 0 0 / 10%);
}

h5 {
  padding: 0;
}


.navigation-bar {
  width: 100%;
  background: white;
  height: 65px;
  display: inline-flex;
  justify-content: flex-start;
  gap: 20px;
  padding-left: 20px;
  border-bottom: 1px solid #d2d2d2;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  .back-button {
    font-size: 25px;
  }

  h3 {
    font-family: 'Inter', serif;
    font-weight: 700;
  }
}

.payment-body {
  background: white;
  height: 100vh;
  width: 100vw;

  .process-payment-body {
    position: fixed;
    bottom: 0;

  }
}

.amount-box {
  height: 100px;
  width: 50%;
  display: flex;
  flex-direction: column;

  .amount-label {
    color: #40434A;
  }

  .amount-paid {
    font-size: 2rem;
    padding: 0;
    margin: 0;
    outline: transparent;
    border: none;
    height: 44px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }
}

.amount-box:first-child {
  align-items: end;

  .amount-paid {
    padding-bottom: 9px;
    text-align: right;
    max-width: 250px;
    width: 100%;
    border-bottom: 1px solid #d2d2d2;

    &:focus, &:active {
      border-bottom: 3px solid #9ccc65 !important;
    }
  }
}

.amount-box:nth-child(2) {
  align-items: start;
  border-left: 1px solid #d2d2d2;
}

.wrapper {
  height: 50px;
  margin: 0 10px;
  display: flex !important;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  //box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
}

.wrapper .option {
  background: #fff;
  height: 100%;
  max-width: 200px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}

.wrapper .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}

.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #9ccc65;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

input[type="radio"] {
  display: none;
}

#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  border-color: #9ccc65;
  background: #9ccc65;
}

#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot {
  background: #fff;
}

#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}

.wrapper .option span {
  font-size: 17px;
  color: #808080;
}

#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #fff;
}


.payment-btn {
  color: white;
  width: 450px;
  height: 60px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.discount-btn {
  background: #9ccc65;
  color: white;
  height: fit-content;
}

.discount-section {
  width: 420px;
  justify-content: center;
  margin: 0 22px;

  .discount-box {
    width: 100%;
    min-width: 300px;
    padding: 10px;
    border: 2px solid lightgrey;
    height: 50px;
    border-radius: 10px;

    p {
      margin: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.payment-pane {
  background-color: white;
}

.amount-box {
  height: 100px;
  width: 50%;
  display: flex;
  flex-direction: column;

  .amount-label {
    color: #40434A;
  }

  .amount-paid {
    font-size: 2rem;
    padding: 0;
    margin: 0;
    outline: transparent;
    border: none;
    height: 44px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }
}

.amount-box:first-child {
  align-items: end;

  .amount-paid {
    padding-bottom: 9px;
    text-align: right;
    max-width: 250px;
    width: 100%;

    &:focus, &:active {
      border-bottom: 3px solid #F1A640 !important;
    }
  }
}

.amount-box:nth-child(2) {
  align-items: start;
  border-left: 1px solid #d2d2d2;
}

.btn {
  display: inline-block;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-size: 30px;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-amount {
  width: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 350px;
  height: 35px;
  border-radius: 10px;
  border: none;
  outline: transparent;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #363636;
}

.barcode-scanner {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  font-size: 24px;
  background-color: #9ccc65;
}

.viewOrder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 8px;
    width: 24px;
    height: 24px;
    background: #9ccc65;
    border-radius: 50%;
  }
}

.text-sm {
  font-size: 18px;
}
</style>
<template>
  <RecycleScroller
      :items="stockProducts"
      :item-size="90"
      class="h-100 scroller w-auto stock-items-list"
      key-field="PLU">

    <template v-slot="{ item, index, active }">
      <Product :stock="item" @click="addToSale(item)"  />
    </template>

  </RecycleScroller>
</template>

<script>
import Product from "@/components/point-of-sale/stock-search/Product";

export default {
  name: "StockItems",
  components: {Product},
  computed: {
    stockProducts() {
      return this.$store.getters.getStockProducts
          .filter(item => item.archived === false)
          .sort((a, b) => a.Description.localeCompare(b.Description));
    },
  },
  methods: {
    addToSale(item) {
      return this.$store.dispatch('getStockSetting', item.PLU)
          .then(async (set) => {
            let cost = Number(item.InclCost.toFixed(2));
            let price = Number(item.SellingIncl.toFixed(2));
            let description = item.Description;

            const getQtyFromPrice = set.getQtyFromPrice;
            let quantity = 1;

            if (cost > price) {
              const result = await Swal.fire({
                allowOutsideClick: false,
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                title: `Cost price is greater than Selling price`,
                confirmButtonText: 'Proceed',
                focusConfirm: false,
              });

              if (!result.isConfirmed) {
                return;
              }
            }

            // Calculate quantity from price only if getQtyFromPrice is true
            if (getQtyFromPrice) {
              this.$store.commit(`setIsTabOpen`,true)
              const { value: totalItemPrice } = await Swal.fire({
                title: 'Enter Total Item Price',
                input: 'number',
                icon : "question",
                inputLabel: 'Total Item Price',
                inputPlaceholder: 'Enter the total price for the items',
                showCancelButton: true,
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputValidator: (value) => {
                  if (!value || isNaN(value) || value <= 0) {
                    return 'Please enter a valid price!';
                  }
                }
              });
              this.$store.commit(`setIsTabOpen`,false)

              if (totalItemPrice) {
                quantity = totalItemPrice / price; // Uses stock setting price
                console.log("Calculated Quantity:", quantity);
              }
            }

            // Prompt for description if required
            if (set.askForDescription || set.askForBoth) {
              description = await this.askForDescription(item);
            }

            // Prompt for price if required
            if (set.askForPrice || set.askForBoth) {
              price = await this.askForPrice(item);
            }

            // Redirect to cooking instructions if required
            if (set.cookingLink) {
              return this.$router.push({
                name: 'instruction',
                query: { plu: item.PLU, saleNumber: this.$route.query.saleNumber }
              });
            }

            // Apply special price if available
            let specialPrice = this.$store.getters.getItemSpecialPrice(item.PLU);
            if (specialPrice) {
              responses.showSuccess(`Special Detected`);
              price = specialPrice;
            }

            // Calculate tourism levy if applicable
            let tourismLevy = 0.0;
            if (set.tourism) {
              let obj = { amount: price, incl: true };
              tourismLevy = await this.$store.dispatch('getTourismLevyAmount', obj);
            }

            // Prepare sale object
            let obj = {
              Plu: item.PLU,
              Description: description,
              Qty: quantity, // Ensuring quantity is always 1
              tourismLevy,
              Unit: price, // Using the same price
              Line: price, // Ensuring total price is the same
              Discount: 0,
              vat: 0,
              instructions: []
            };


            // Add to sale
            this.$store.commit('addToCurrentSaleLines', obj);

            return this.$store.dispatch(`calculateTipAmount`);
          });
    },

    askForPrice(item) {
      this.$store.commit('setIsTabOpen', true)
      return Swal.fire({
        allowOutsideClick: false,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        title: `Set Selling Price`,
        html: `<h6 >Description : ${item.Description}</h6>
               <h6 >Current Price : ${$utils.formatMoney(item.SellingIncl)}</h6>
               <input type="number" step="0.01" id="selling-prc" class="swal2-input" placeholder="Selling Price" style="max-width: 70%">`,
        confirmButtonText: 'Set Price',
        focusConfirm: false,
        preConfirm: async () => {
          let value = Swal.getPopup().querySelector('#selling-prc').value;
          if (!value) {
            Swal.showValidationMessage(`Please enter a valid Selling Price`)
          }
          return parseFloat(value);
        }
      }).then(result => {
        this.$store.commit('setIsTabOpen', false)
        if (result.isConfirmed) {
          return result.value;
        }
        return parseFloat(item.SellingIncl.toFixed(2))
      })
    },
    askForDescription(item) {
      this.$store.commit('setIsTabOpen', true)
      return Swal.fire({
        allowOutsideClick: false,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        title: `Set Description`,
        html: `<h6 >Description : ${item.Description}</h6>
               <textarea id="desc-ask" class="swal2-input" placeholder="Description" style="max-width: 70%">`,
        confirmButtonText: 'Set Description',
        focusConfirm: false,
        preConfirm: async () => {
          let value = Swal.getPopup().querySelector('#desc-ask').value
          if (!value || value.toString().trim() === "") {
            Swal.showValidationMessage(`Please enter a valid Description`)
          }
          return value;
        }
      }).then(result => {
        this.$store.commit('setIsTabOpen', false)
        if (result.isConfirmed) {
          return result.value;
        }
        return item.Description
      })
    },

  }, watch: {}
}
</script>

<style lang="scss" scoped>
.stock-items-list {
  scroll-behavior: smooth;
}

.grid-container {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 32% 34% 32%;
  padding: 10px 10px 80px 10px;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) and (max-width: 940px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 400px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media (max-width: 650px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 650px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>

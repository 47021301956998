import {authentication} from "@/services/auth";
import {Device} from "@/services/Device";
import {responses} from "@/utils/responses";
import Swal from "sweetalert2";
import Vue from 'vue'
import {utils} from "@/utils/utils";
import {errorHandler} from "@/services/errorHandler";
import queryStringify from "qs-stringify";
import { VueEditor } from 'vue2-editor';
import {dateTimeManager} from "@/services/TimeManager";

Vue.component('vue-editor', VueEditor);
window.serverApi = `https://overflow.abc.co.zm:8443/OnlineSalesServer/`
// window.serverApi = `http://localhost:8080/OnlineSalesServer/`

window.kazangApi = `https://realtime.abc.co.zm:8443/kazangApi/api/`
 // window.kazangApi = `http://c2:8080/kazangApi/api/`

window.gateWayApi = `https://lna.abc.co.zm/KazangPaymentGateway/GateWay/`
// window.gateWayApi = `http://192.168.1.244:8181/KazangPaymentGateway/GateWay/`

 window.franchiseTomcatApi = `https://overflow.abc.co.zm:8443/Franchise_OnlineServer/`
// window.franchiseTomcatApi = `http://192.168.1.175:8082/Franchise_OnlineServer/`

window.ticketApi = 'https://lna.abc.co.zm/TicketApp/'
// window.ticketApi = `http://192.168.1.123:8181/TicketApp/`;

window.SmartInvoice = `https://lna.abc.co.zm/SynergyApi/api/v1/`

window.backOfficeTomcatApi = `https://overflow.abc.co.zm:8443/Lyt-SoftBackOfficeApi/`
// window.backOfficeTomcatApi = `http://localhost:8080/Lyt-SoftBackOfficeApi/`

window.backOfficePlatform = `https://admin.chrilantech.com`;

window.synergyApi = `https://lna.abc.co.zm/TicketApp/`
// window.synergyApi = `http://192.168.1.240:8181/SynergyApi/api/v1/`

window.serverApiGET = franchiseTomcatApi+`proxyApi/onlineSalesServer/GET`;

window.backOfficeApiPOST = franchiseTomcatApi+`proxyApi/lytSoftBackOffice/POST`;
window.backOfficeApiGET = franchiseTomcatApi+`proxyApi/lytSoftBackOffice/GET`;

window.gateWayApiPOST = franchiseTomcatApi+`proxyApi/gateWay/POST`;
window.gateWayApiGET = franchiseTomcatApi+`proxyApi/gateWay/GET`;

window.subscriptionApiPOST = franchiseTomcatApi+`proxyApi/subscriptionApi/POST`;
window.subscriptionApiGET = franchiseTomcatApi+`proxyApi/subscriptionApi/GET`;
Vue.prototype.$dateTimeManager = dateTimeManager;
window.auth = authentication;
window.Device = Device;
window.responses = responses;
window.errorHandler = errorHandler
window.routeExceptions = ['login','forgot-password','kazangOnline','test-success','test-failed','initKazangOnline',
    'signup','custom-install','custom-cart'];

const EventBus = new Vue()
window.EventBus = EventBus
window.log = (val) => console.log(val);
window.Swal = Swal;
window.getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
window.generateUUID = () => {
    let uuid = '', i, random;
    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
            uuid += '-';
        }
        uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
}

window.$utils = utils;
Vue.prototype.$utils = utils;

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    showCloseButton: true,
    customClass: 'toastr',
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
window.Toast = Toast;
window.stringify = (object) => queryStringify(object);
window.encryptObj = (object) => window.btoa(JSON.stringify(object));

window.openUrl = (url,target = '_blank') => window.open(url,target);
window.isValidString = (string) => {
    if (!string) return false;
    string = string.toString().trim();
    return !(string === `null` || string === `undefined` || string === ``);

}
window.stripMimeType = (base64) => {
    const pattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
    return base64.replace(pattern, '');
}


